<template>
  <div id="membership-view">
    <div class="mb-2">
      <a @click="$router.back()">Back</a>
    </div>

    <v-row v-if="membershipData">
      <v-col
        cols="8"
      >
        <v-card>
          <v-card-title>Membership Info</v-card-title>
          <v-card-text>
            <div>ID: {{ membershipData.id }}</div>
            <div>
              Plan:
              <router-link
                :to="{ name: 'product-view', query: { tab: 'PLANS' }, params: { id : membershipData.plan.sku.product_id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ t(membershipData.plan.sku.name) }}
              </router-link>
            </div>
            <div>
              Current Price:
              {{ membershipData.price.selling_price }}
              {{ t(membershipData.price.name) }}
              (min quantity: <b>{{ membershipData.price.min_quantity }}</b>)
            </div>
          </v-card-text>
        </v-card>

        <v-card class="mt-4">
          <v-card-title>Dates</v-card-title>
          <v-card-text>
            <div>
              Next Billing Date:
              <span v-if="membershipData.next_billing_at">{{ membershipData.next_billing_at | dateSimple }}</span>
              <span
                v-if="membershipData.next_billing_at"
                class="ml-2"
              >({{ membershipData.next_billing_at | diff('days') }} days)</span>
            </div>
            <div>
              Started:
              <span v-if="membershipData.started_at">{{ membershipData.started_at | dateSimple }}</span>
            </div>

            <div>
              Ended:
              <span v-if="membershipData.ended_at">{{ membershipData.ended_at | dateSimple }}</span>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="mt-4">
          <v-card-title>Next Recursion</v-card-title>
          <v-card-text>
            <template v-if="membershipData.next">
              <p>This membership's next billing will be:</p>
              <p>SKU: <b>{{ t(membershipData.next.plan.sku.name) }}</b></p>
              <p>Price: {{ t(membershipData.next.price.name) }} - <b>{{ membershipData.next.price.selling_price }}</b> (min quantity = {{ membershipData.next.price.min_quantity }})</p>
            </template>
            <template v-else>
              The membership will continue to use current state, same price, same SKU plan.
              <p>Note: If this is trial membership, it will terminate at the billing date.</p>
            </template>

            <v-btn @click="aside = true; resource = membershipData.next">
              Set Next membership
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="4"
      >
        <v-card>
          <v-card-title>User</v-card-title>
          <v-card-text v-if="membershipData.user">
            <div class="mb-3">
              <router-link
                class="font-weight-medium text-decoration-none"
                :to="{ name: 'channel-user-view', params: { id: membershipData.user_id } }"
              >
                {{ membershipData.user.email }}
              </router-link>
            </div>
            <p>user id:{{ membershipData.user.channel_user_id }}</p>
            <p>staff id:{{ membershipData.user.employee_id }}</p>
          </v-card-text>

          <v-card-text v-else>
            This membership has NOT been redeemed.
          </v-card-text>
        </v-card>

        <v-card class="mt-4">
          <v-card-title>Membership Group</v-card-title>
          <v-card-text>
            <div class="mb-3">
              <!-- <router-link
                class="font-weight-medium text-decoration-none"
                :to="{ name: 'channel-user-view', params: { id: membershipData.group.user_id } }"
              > -->
              {{ membershipData.group.user.email }}
              <!-- </router-link> -->
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <NextMembershipAside
      v-if="aside"
      :active="aside"
      :membership="membershipData"
      :resource="resource"
      @changed="() => { closeAside(false); reloadMembership() }"
      @aside="bool => { !bool && closeAside(false); }"
    />
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import router from '@/router'
import { dateSimple, diff } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import NextMembershipAside from '../membership-resource/NextMembershipAside.vue'
import useMembershipView from './useMembershipView'

export default {
  filters: { dateSimple, diff },

  components: {
    NextMembershipAside,
  },

  setup() {
    const { loadMembership, membershipData } = useMembershipView()

    const reloadMembership = () => {
      membershipData.value = null

      return loadMembership(router.currentRoute.params.id).then(() => {

      })
    }

    reloadMembership()

    const aside = ref(false)
    const resource = ref(null)
    const closeAside = () => { aside.value = false }

    return {
      membershipData,
      reloadMembership,
      t,

      aside,
      resource,
      closeAside,
    }
  },
}
</script>
