import { useExtractErrors } from '@/composables'
import { fetchSkuPriceGroups, fetchSkuPrices, storePrice, storePriceGroup, updatePrice } from '@api/product'
import { ref } from '@vue/composition-api'

export default function usePriceForm() {
  const priceErrors = ref([])
  const loading = ref(false)
  const priceData = ref(null)
  const priceGroupData = ref(null)
  const priceGroups = ref([]) // of a sku
  const prices = ref([]) // of a sku

  /**
   * With Tangible, we store price group first,
   * then store price inside the group.
   * group name is irrelevant.
   */
  const storeTangibleSkuPriceForm = async (skuId, form) => {
    if (!priceGroupData.value) {
      const group = {
        name: form.name,
        sku_id: skuId,
      }

      loading.value = true
      priceErrors.value = []

      const res = await storePriceGroup(group)
        .catch(err => {
          priceErrors.value = useExtractErrors(err)

          return Promise.reject(err)
        })
        .finally(() => {
          loading.value = false
        })
      if (!res) return Promise.reject()

      priceGroupData.value = res.data.data
    }

    const price = { ...form }
    price.price_group_id = priceGroupData.value.id

    loading.value = true

    return storePrice(price)
      .then(res => {
        priceData.value = res.data.data

        return Promise.resolve(res)
      })
      .catch(err => {
        priceErrors.value = useExtractErrors(err)

        console.log('err is?', err)
        console.log('err is?', priceErrors.value)

        return Promise.reject(err)
      })
      .finally(() => {
        loading.value = false
      })
  }

  const updatePriceForm = (priceId, form) => {
    loading.value = true

    return updatePrice(priceId, { ...form })
      .then(res => {
        priceData.value = res.data.data

        return res
      })
      .catch(err => {
        console.log(err)

        return Promise.reject(err)
      })
      .finally(() => {
        loading.value = false
      })
  }

  const loadPriceGroups = sku =>
    fetchSkuPriceGroups({ sku_id: sku.id })
      .then(res => {
        const { records, pagination } = res.data.data
        priceGroups.value = records

        // remove loading state

        return res
      })
      .catch(error => {
        console.log(error)

        return Promise.reject(error)
      })
      .finally(() => {
        loading.value = false
      })

  const loadPrices = sku =>
    fetchSkuPrices({ sku_id: sku.id })
      .then(res => {
        const { records, pagination } = res.data.data
        prices.value = records

        // remove loading state

        return res
      })
      .catch(error => {
        console.log(error)

        return Promise.reject(error)
      })
      .finally(() => {
        loading.value = false
      })

  return {
    loading,
    priceErrors,
    priceData,
    priceGroups,
    priceGroupData,

    storeTangibleSkuPriceForm,
    updatePriceForm,
    loadPriceGroups,

    loadPrices,
    prices,
  }
}
