<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-select
          v-model="form.plan_id"
          placeholder="Plan"
          :items="planOptions"
          item-text="title"
          item-value="value"
          @input="selectedPlan"
        ></v-select>

        <v-select
          v-model="form.price_id"
          placeholder="Price"
          :items="priceOptions"
          :disabled="!form.plan_id"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-select>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import usePlanForm from '@/modules/product/views/plan-resource/usePlanForm'
import usePriceForm from '@/modules/product/views/price-resource/usePriceForm'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'

export default {

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      plan_id: props.resource ? props.resource.plan_id : null,
      sku_id: props.resource ? props.resource.plan.sku.id : null,
      price_id: props.resource ? props.resource.price_id : null,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) {
        emit('submit', form.value)
      }
    }

    const { prices, loadPrices } = usePriceForm()
    const { plans, loadPlans } = usePlanForm()

    const planOptions = computed(() => plans.value.map(p => ({ title: `${p.sku.name.en} - ${p.sku.sku_number}`, value: p.id })))

    const priceOptions = computed(() => prices.value.map(p => ({ title: `${p.name.en} - ${p.selling_price}`, value: p.id })))

    const selectedPlan = id => {
      const plan = plans.value.find(p => p.id === id)
      form.value.sku_id = plan.sku.id

      loadPrices(plan.sku).then(() => {
        const { price_id } = form.value
        if (price_id && !prices.value.find(p => p.id === price_id)) {
          form.value.price_id = null
        }
      })
    }

    onMounted(() => {
      loadPlans().then(() => {
        if (props.resource) {
          selectedPlan(props.resource.plan.id)
        }
      })
    })

    return {
      form,
      formElem,

      validate,
      required,

      planOptions,
      priceOptions,
      selectedPlan,
    }
  },
}
</script>
