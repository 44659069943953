import axios from '@axios'

const fetchMemberships = queryParams => axios.get('/admin/memberships', { params: queryParams })

const fetchMembership = id => axios.get(`/admin/memberships/${id}`)

const updateMembership = (id, data) => axios.put(`/admin/memberships/${id}`, data)

const storeNextMembership = (id, data) => axios.post(`/admin/memberships/${id}/next`, data)

export { fetchMemberships, fetchMembership, updateMembership, storeNextMembership }
