import { useNotifyErrors, useNotifySuccess } from '@/composables'
import { fetchMembership, updateMembership } from '@api/membership'
import { ref } from '@vue/composition-api'

export default function useMembershipView() {
  const membershipData = ref(null)
  const loading = ref(false)

  // fetch data
  const loadMembership = id => {
    membershipData.value = null
    loading.value = true

    return fetchMembership(id)
      .then(res => {
        membershipData.value = res.data.data

        return res
      })
      .catch(useNotifyErrors)
      .finally(() => {
        loading.value = false
      })
  }

  // update/touch data, for event resending
  const touchMembership = id => updateMembership(id).then(() => useNotifySuccess({ content: 'pending to resend' }))

  return {
    loading,

    membershipData,

    loadMembership,
    touchMembership,
  }
}
