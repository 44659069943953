import { fetchPlans, updatePlan } from '@api/product'
import { ref } from '@vue/composition-api'

export default function usePlanForm() {
  const planErrors = ref([])
  const loading = ref(false)
  const planData = ref(null)
  const plans = ref([]) // of a sku

  /**
   * With Tangible, we store plan group first,
   * then store plan inside the group.
   * group name is irrelevant.
   */

  const updatePlanForm = (planId, form) => {
    loading.value = true

    return updatePlan(planId, { ...form })
      .then(res => {
        planData.value = res.data.data

        return res
      })
      .catch(err => {
        console.log(err)

        return Promise.reject(err)
      })
      .finally(() => {
        loading.value = false
      })
  }

  const loadPlans = (sku = null, queryParams) =>
    fetchPlans({ sku_id: sku?.id || undefined, ...queryParams })
      .then(res => {
        const { records, pagination } = res.data.data
        plans.value = records

        // remove loading state

        return res
      })
      .catch(error => {
        console.log(error)

        return Promise.reject(error)
      })
      .finally(() => {
        loading.value = false
      })

  return {
    loading,
    planErrors,
    planData,
    plans,

    updatePlanForm,
    loadPlans,
  }
}
